<template>
  <b-card>
    <div class="d-flex">
      <b-form-input
        id="filter-input"
        v-model="filter"
        type="search"
        class="col-md-4 col-xl-2"
        placeholder="Cerca..."
      ></b-form-input>
      <b-button class="mb-3 ml-auto" variant="success"
                @click="$router.push('/track-reservation/create')"
        >+ Crea Nuova</b-button
      >
    </div>
    <b-table
      hover
      bordered
      :filter="filter"
      responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      class="contact-table"
    >
        <!-- A custom formatted column -->
      <template #cell(track_type)="data" width="100" class="text-center">
        <span v-if="data.item.track_type === 'round_trip'"> Andata e Ritorno</span>
        <span v-else> Solo Andata</span>
      </template>
      <template #cell(patient_or_clinic)="data" width="60" class="text-center">
          <span v-if="data.item.user_patient_id && data.item.caregiver_vector">{{data.item.caregiver_vector.name}}</span>
          <span v-else-if="data.item.user_caregiver_id && data.item.caregiver">
            {{data.item.caregiver.authinfo.name}} {{data.item.caregiver.authinfo.surname}}
        </span>
          <span v-else-if="data.item.user_patient_id && data.item.patient">
            {{data.item.patient.authinfo.name}} {{data.item.patient.authinfo.surname}}
        </span>
          <span v-if="data.item.user_clinic_id">(Clinica) {{data.item.clinic.name}}</span>
        <span v-if="data.item.user_clinic_id">(Clinica) {{data.item.clinic.name}}</span>
      </template>
      <template #cell(types_of_service)="data" width="60" class="text-left">
        <span>{{data.item.track.description}}</span>
      </template>
      <template #cell(Azioni)="data" width="100" class="text-center">
        <a class="mx-1 cursor-pointer" @click="$router.push('/track-reservation/edit/'+data.item.id)"
        ><feather type="edit-3" class="feather-sm"></feather
        ></a>
        <a
            class="mx-1 cursor-pointer text-danger"
            @click="deleteItem(data.item)"
        ><feather type="trash-2" class="text-danger feather-sm"></feather
        ></a>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"
    ></b-pagination>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <b-modal
      ref="edit-modal"
      id="editModal"
      hide-header
      @ok="save"
      @cancel="close"
      ok-title="Salva"
      cancel-title="Chiudi"
    >
      <h4 class="mb-4">{{ formTitle }}</h4>
      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Name</h6>
          <b-form-input
            v-model="editedItem.authinfo.name"
            placeholder="Inserisci Nome"
            name="name-group"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Email</h6>
          <b-form-input
            v-model="editedItem.authinfo.email"
            placeholder="Inserisci email"
            name="name-group"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Password</h6>
          <b-form-input
              v-model="editedItem.authinfo.password"
              placeholder="Inserisci password"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
export default {
  name: "TrackReservationList",
  data: () => ({
    sortBy: "id",
    fields: [
      { key: "id", sortable: true },
      { key: "patient_or_clinic", label: "Paziente/Clinica" },
      { key: "track_type", label: "Tipologia" },
      { key: "status", label: "Stato" },
      { key: "departure_date", label: "Data di partenza" },
      { key: "types_of_service", label: "Servizi" },
      "Azioni"
    ],
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    editedItem: {
      id: "",
      authinfo: {
        name: '',
        email: '',
      },
    },
    defaultItem: {
      id: "",
      authinfo: {
        name: '',
        email: '',
      },
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("setDataTable", [])
      const res = await this.axios.get('track-reservations')
      await this.$store.dispatch("setDataTable", res.data)
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = item;
      this.$refs["edit-modal"].show();
    },

    newItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      await this.$swal.fire({
        title: 'Elimina Prenotazione!',
        text: 'Sei sicuro di voler eliminare?',
        icon: 'warning',
        confirmButtonColor: '#1f2335',
        confirmButtonText: 'Si, Elimina!',
        showCancelButton: true,
        cancelButtonText: 'No!'
      }).then(async result => {
        if (result.value) {
          const res = await this.axios.delete('track-reservation/'+item.id)

          if (res.data.status === 'success') {
            this.items.splice(index, 1);
          }

          this.$swal.fire({
            title: 'Eliminazione Prenotazione!',
            text: res.data.message,
            icon: res.data.status,
            confirmButtonColor: '#1f2335',
          })
        }
      });

      await this.initialize()
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async save() {
      const res = await this.axios.post('track-reservation', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio Utente',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      this.close();
      await this.initialize();
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuovo Utente Admin" : "Modifica Utente Admin";
    },
    rows() {
      return this.items.length;
    },
    items () {
      return this.$store.state.dataTable
    }
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>